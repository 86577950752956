<template>
  <v-container class="fill-height">
    <v-row>
      <v-col cols="12">
        <v-card outlined class="pt-6 pb-4 px-6 ma-auto" style="width: 500px; max-width: 100%">
          <NewPasswordForm />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import NewPasswordForm from "../components/NewPasswordForm";

export default {
  name: 'NewPassword',

  components: {
    NewPasswordForm
  },

  computed: {

  },

  methods: {
  },

  beforeMount() {
    const { query } = this.$route;
    if (!query.token || !query.userId) {
      this.$router.push('/');
    }
  },

  async mounted() {

  },

  data: () => ({

  }),
}
</script>

<style lang="scss">

</style>