<template>
  <v-container class="fill-height">
    <v-row>
      <v-col cols="12">
        <v-card outlined class="pt-6 pb-4 px-6 ma-auto" style="width: 500px; max-width: 100%">
          <RecoverPasswordForm />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import RecoverPasswordForm from "../components/RecoverPasswordForm";

export default {
  name: 'RecoverPassword',

  components: {
    RecoverPasswordForm
  },

  computed: {

  },

  methods: {
  },

  async mounted() {

  },

  data: () => ({

  }),
}
</script>

<style lang="scss">

</style>