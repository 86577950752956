<template>
  <div class="text-center">
    <h2 class="mb-4 ">Password Reset</h2>
    <v-form v-model="valid" ref="form" @submit.prevent="handleFormSubmit">

      <v-text-field
          ref="emailInput"
          v-model.trim="email"
          label="Enter E-mail"
          solo
          outlined
          dense
          flat
          required
          :rules="emailRules"
          class="mb-2 font-size-15"
          hide-details="auto"
          :error-messages="errors.email"
          @change="resetFormValidation"
      ></v-text-field>

      <v-btn type="submit" class="custom--btn" height="40" color="cyan accent-4 "  dark block depressed>Reset Password</v-btn>

      <div>
        <v-divider class="mt-5 mb-4"></v-divider>

        <small class="d-block text-muted"><span class="grey--text">Remember your password?</span> <router-link to="/signin">Sign In</router-link></small>
      </div>
    </v-form>
  </div>
</template>

<script>
import authApi from '../api/auth';
import { emailRules, requiredRules } from '../js/validators';
import {mapMutations} from "vuex";
import { showNotification } from "../js/helpers";


export default {
  name: 'RecoverPasswordForm',

  computed: {

  },

  mounted() {
  },

  methods: {
    ...mapMutations(['setLoading']),
    resetFormValidation() {
      this.errors.email = '';
    },
    handleFormSubmit(e) {
      this.errors.email = '';
      this.$refs.form.validate();

      this.$nextTick(async function() {
        if (!this.valid) { return }

        this.setLoading(true);

        const body = { email: this.email };
        const { status } = await authApi.recover.call(this, body);
        this.setLoading(false);

        if (!status) { return;  }

        showNotification({ title: 'Future instructions were successfully sent to your email' });

        this.$refs.form.resetValidation();
        if (this.$refs.emailInput) { this.$refs.emailInput.blur() }
        this.email = '';

        this.$router.push(`/signin?email=${body.email}`);
      });
    }
  },

  data: () => ({
    valid: false,

    email: '',
    emailRules: [...requiredRules, ...emailRules],

    errors: { email: '' }
  }),
}
</script>

<style lang="scss">

</style>
