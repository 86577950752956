<template>
  <div class="text-center">
    <h2 class="mb-4 ">New Password</h2>
    <v-form v-model="valid" ref="form" @submit.prevent="handleFormSubmit">

      <v-text-field
          ref="password"
          v-model.trim="password"
          label="Enter Password"
          solo
          outlined
          dense
          flat
          required
          :rules="passwordRules"
          hide-details="auto"
          class="mb-5 font-size-15"
          :type="showPassword ? 'text' : 'password'"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="showPassword = !showPassword"
          :error-messages="errors.newPassword"
          @input="resetFormValidation"
      ></v-text-field>

      <v-text-field
          ref="repeatPassword"
          v-model.trim="repeatPassword"
          label="Repeat Password"
          solo
          outlined
          dense
          flat
          required
          :rules="passwordRules"
          hide-details="auto"
          class="mb-5 font-size-15"
          :type="showRepeatPassword ? 'text' : 'password'"
          :append-icon="showRepeatPassword ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="showRepeatPassword = !showRepeatPassword"
          :error-messages="errors.confirmPassword || confirmPasswordError"
          @input="resetFormValidation"
      ></v-text-field>

      <v-btn type="submit" class="custom--btn" height="40" color="cyan accent-4 "  dark block depressed>Save Password</v-btn>
    </v-form>
  </div>
</template>

<script>
import authApi from '../api/auth';
import { passwordRules, requiredRules } from '../js/validators';
import {mapMutations} from "vuex";
import {showNotification} from "../js/helpers";


export default {
  name: 'NewPasswordForm',

  computed: {
    confirmPasswordError() {
      return this.repeatPassword.length && this.password !== this.repeatPassword ? 'Password must match' : '';
    }
  },

  mounted() {
  },

  methods: {
    ...mapMutations(['setLoading']),
    resetFormValidation() {
      this.errors.newPassword = '';
      this.errors.confirmPassword = '';
    },
    handleFormSubmit(e) {
      this.resetFormValidation();
      if (!this.$refs.form.validate() || !this.valid) { return }

      this.$nextTick(async function() {
        this.setLoading(true);

        const { status } = await authApi.resetPassword.call(this, {
          newPassword: this.password,
          confirmPassword: this.repeatPassword,
          userId: this.$route.query?.userId,
          token: this.$route.query?.token
        });

        this.setLoading(false);

        if (!status) { return; }

        showNotification({ title: 'Password was successfully changed' });
        this.$router.push('/signin');
      });
    }
  },

  data: () => ({
    valid: false,
    showPassword: false,
    showRepeatPassword: false,

    password: '',
    repeatPassword: '',

    passwordRules: [...requiredRules, ...passwordRules],

    errors: { newPassword: '', confirmPassword: '' }
  }),
}
</script>

<style lang="scss">

</style>
